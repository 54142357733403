export const envTypes = {
  development: "development",
  staging: "staging",
  production: "production",
};

// const themes = {
//   kfcTheme: "kfcTheme",
//   pizzaTheme: "pizzaTheme",
// };
const env = envTypes.production;
let dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  socketUrl,
  baseUrl,
  credentials,
  dfpBaseUrlV3,
  basicSocketUrl,
  dfpBaseV3Url;

export const skipLogin = env === "development" ? true : false;

if (env === envTypes.development) {
  dfpBaseUrl = "http://localhost:8002/rest/v2/realm/";
  dfpBaseUrlV3 = "http://localhost:8002/rest/v3/realm/";
  loginPath = "http://localhost:8000/login_crave";
  baseUrl = "http://localhost:8002";
  domain = "http://localhost:3001/";
  socketUrl = "ws://localhost:8002/rest/v2/realm/ws";
  credentials = "include";
  dfpBaseV3Url = "http://localhost:8002/rest/v3/";
  basicSocketUrl = "ws://localhost:8002/";
}
if (env === envTypes.staging) {
  dfpBaseUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/realm/";
  dfpBaseUrlV3 = "https://dfp-api.nonprod.yumds.com/rest/v3/realm/";
  loginPath = "https://dfp-api.nonprod.yumds.com/login_crave";
  socketUrl = "wss://dfp-api.nonprod.yumds.com/rest/v2/realm/ws";
  baseUrl = "https://dfp-api.nonprod.yumds.com";
  credentials = "include";
  dfpBaseV3Url = "https://dfp-api.nonprod.yumds.com/rest/v3/";
  basicSocketUrl = "wss://dfp-api.nonprod.yumds.com/";
  domain = "https://re-api.nonprod.yumds.com/";
}

if (env === envTypes.production) {
  dfpBaseUrlV3 = "https://dfp-api.yumds.com/rest/v3/realm/";
  dfpBaseUrl = "https://dfp-api.yumds.com/rest/v2/realm/";
  dfpApi = "https://dfp-api.yumds.com/rest/v2/";
  // subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.yumds.com/login_crave";
  domain = "https://aibot.yumds.com/";
  socketUrl = "wss://dfp-api.yumds.com/rest/v2/realm/ws";
  baseUrl = "https://dfp-api.yumds.com";
  credentials = "include";
  dfpBaseV3Url = "https://dfp-api.yumds.com/rest/v3/";
  basicSocketUrl = "wss://dfp-api.yumds.com/";
}
// export const currentTheme = themes.kfcTheme;
export {
  dfpBaseUrl,
  dfpBaseUrlV3,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  socketUrl,
  env,
  baseUrl,
  credentials,
  dfpBaseV3Url,
  basicSocketUrl,
};
