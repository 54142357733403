import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import SidebarBtns from "../../Components/SidebarBtns/SidebarBtns";
import { Link, useLocation } from "react-router-dom";
import { ChatQuoteIcon, PlusIcon } from "../../icons";
import { Loader } from "../../Components/Loaders/Loaders";
import Error from "../../Components/Error/Error";
import ConversationList from "../../Components/ConversationList/ConversationList";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  useDeleteConversationMutation,
  usePinConversationMutation,
  useUpdateTitleMutation,
} from "../../services/conversationService";
import {
  setPrevSelectedConversation,
  setSelectedConversation,
} from "../../services/selectedConversation";
import { generateCustomId } from "../../utils/utils";
import Cookies from "js-cookie";
import { FaCheckCircle } from "react-icons/fa";
import { BsFillPinFill, BsThreeDotsVertical } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { PiPushPinSimpleSlashFill } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import { setNewConversation } from "../../services/newConversationSlice";

const AgentSidebar = ({
  data,
  error,
  isFetching,
  newChat,
  setNewChat,
  setShowWelcome,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("id");

  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.length > 0) {
      dispatch(setNewConversation(false));
      setNewChat();
    } else {
      dispatch(setNewConversation(true));
    }
    if (data && data.length === 0) setShowWelcome(true);
    // if (myParam) setShowWelcome(true);
  }, [data, setShowWelcome, myParam, dispatch, setNewChat]);

  const handleNewChat = (e) => {
    const id = generateCustomId();
    e.preventDefault();
    dispatch(setNewConversation(true));

    setNewChat([{ conversation_id: "nofetch", conversation_name: "New Chat" }]);
    // dispatch(setPrevSelectedConversation(selectedConversation));
    // dispatch(setSelectedConversation(id));
  };

  const handleRetry = () => {};
  return (
    <aside
      className={`w-full sm:w-2/4  lg:w-1/4  z-10  fixed  left-0 bottom-0  z-1  flex flex-col overflow-visible  transform ease-in-out duration-300 translate-x-0 top-[91px] bg-socket-dark  ${
        window.mapp ? "top-0" : ""
      }`}
    >
      <SidebarHeader handleNewChat={handleNewChat} />
      <Scrollbars
        autoHide
        renderTrackHorizontal={(props) => (
          <div {...props} className="scrollbar-track-horizontal" />
        )}
        renderThumbHorizontal={(props) => (
          <div
            {...props}
            className="scrollbar-thumb-horizontal bg-gray-500 rounded-full "
          />
        )}
      >
        <div className="pb-5 px-3 pl-6">
          {isFetching ? (
            <div className="flex items-center justify-center mt-10">
              <Loader />
            </div>
          ) : error ? (
            <>
              <Error
                msg={error.error ? error.error : error.data.detail}
                classes={"bg-primary-white"}
              />
              <div className="text-center flex justify-center">
                <button
                  onClick={() => handleRetry()}
                  type="button"
                  className="text-center  mt-5 text-primary-white bg-primary-red hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                >
                  Refetch
                </button>
              </div>
            </>
          ) : (
            <nav className="side-menu-nav flex-grow flex flex-col overflow-visible scroll-smooth">
              <div>
                <AgentConversationList
                  conversations={data}
                  setShowSidebar={true}
                  newChat={newChat}
                  myParam={myParam}
                  setNewConversation={setNewConversation}
                />
              </div>
            </nav>
          )}
        </div>
      </Scrollbars>

      <footer className="side-menu-footer flex-shrink-0 bg-skyblue  text-white py-5 border-t border-primary-grey">
        <SidebarBtns myParam={myParam} />
      </footer>
    </aside>
  );
};

const SidebarHeader = ({ handleNewChat }) => {
  return (
    <header className="side-menu-header flex-shrink-0 bg-skyblue p-2 pt-5 px-3 pl-6">
      <div className="flex items-center ">
        <div className="flex-1">
          <div>
            <h3 className="text-primary-white text-xl  font-semibold">
              Chat Archive
            </h3>
          </div>
        </div>
        <div className="">
          <Link
            to={"#"}
            className="text-primary-white bg-primary-black-dark"
            onClick={(e) => handleNewChat(e)}
            id="newChat"
          >
            <PlusIcon />
          </Link>
        </div>
      </div>
    </header>
  );
};

const AgentConversationList = ({
  conversations,
  setShowSidebar,
  newChat,
  myParam,
  setNewConversation,
}) => {
  console.log("🚀 ~ conversations:", conversations);
  const dispatch = useDispatch();

  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const inputRef = useRef([]);

  const [updateConversation, updateConversationInfo] = useUpdateTitleMutation();
  const [pinConversation, pinConversationInfo] = usePinConversationMutation();
  const [deleteConversation, deleteConversationInfo] =
    useDeleteConversationMutation();

  // const [isEditing, setIsEditing] = useState(false);
  const [editingConversation, setEditingConversation] = useState("");

  const [text, setText] = useState("");

  const hasPinned = conversations?.find((conversation) => conversation.pin);

  // const hasPinned = conversations?.conversations?.find(
  //   (conversation) => conversation.pin
  // );
  // const [getHistory, { data, error, isLoading }] = useLazyGetHistoryQuery();

  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    if (newChat && newChat.length > 0) {
      setActiveLink(null);
      dispatch(setSelectedConversation(null));
    }
  }, [dispatch, newChat]);

  useEffect(() => {
    dispatch(setPrevSelectedConversation(selectedConversation));

    if (conversations && conversations.length > 0) {
      dispatch(setSelectedConversation(conversations[0]._id));
      setActiveLink(conversations[0]._id);
    } else {
      dispatch(setSelectedConversation(null));
    }
  }, [conversations, dispatch]);

  const handleLinkClick = (e, conversation_id) => {
    e.preventDefault();
    setActiveLink(conversation_id);
    dispatch(setPrevSelectedConversation(selectedConversation));

    dispatch(setSelectedConversation(conversation_id));
    dispatch(setNewConversation(false));

    if (!isDesktop) {
      setShowSidebar(false);
    }
  };

  const pinConversationEvent = (conversationId) => {
    pinConversation(conversationId);
  };

  const deleteConversationEvent = (conversationId) => {
    const body = { conversation_id: conversationId, myParam };
    deleteConversation(body);
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleEdit = (conversation_id, conversation_name, index) => {
    setEditingConversation(conversation_id);
    setText(conversation_name);
    if (inputRef.current[index]) inputRef.current[index].focus();

    setTimeout(() => {
      if (inputRef.current[index]) inputRef.current[index].focus();
    }, 500);
  };

  const handleBlur = (e, conversation_id) => {
    setText(e.target.value);
    setEditingConversation("");
    const body = {
      conversation_id: conversation_id,
      new_title: e.target.value,
      myParam,
    };
    updateConversation(body);
    // Additional logic you want to execute when input loses focus
  };

  return (
    <>
      {hasPinned && (
        <>
          <h6 className="text-primary-white font-semibold mt-5 text-sm">
            Pinned Messages
          </h6>
          <AgentConversationTxtList
            conversations={conversations}
            activeLink={activeLink}
            handleLinkClick={handleLinkClick}
            pinConversationEvent={pinConversationEvent}
            deleteConversationEvent={deleteConversationEvent}
            pinned={true}
            text={text}
            handleInputChange={handleInputChange}
            handleEdit={handleEdit}
            editingConversation={editingConversation}
            handleBlur={handleBlur}
            setEditingConversation={setEditingConversation}
            inputRef={inputRef}
            isDesktop={isDesktop}
          />
        </>
      )}

      <h6 className="text-primary-white font-semibold mt-5 text-sm">
        All Messages
      </h6>
      <AgentConversationTxtList
        conversations={conversations}
        activeLink={activeLink}
        handleLinkClick={handleLinkClick}
        pinConversationEvent={pinConversationEvent}
        deleteConversationEvent={deleteConversationEvent}
        pinned={false}
        text={text}
        handleInputChange={handleInputChange}
        handleEdit={handleEdit}
        editingConversation={editingConversation}
        handleBlur={handleBlur}
        setEditingConversation={setEditingConversation}
        inputRef={inputRef}
        isDesktop={isDesktop}
      />
    </>
  );
};

const AgentConversationTxtList = ({
  conversations,
  activeLink,
  handleLinkClick,
  pinConversationEvent,
  deleteConversationEvent,
  pinned = false,
  editingConversation,
  text,
  handleInputChange,
  handleBlur,
  handleEdit,
  setEditingConversation,
  inputRef,
  isDesktop,
}) => {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const requestBased = Cookies.get("requestBased");

  return (
    <ul className="">
      {conversations &&
        conversations?.map(
          ({ _id: conversation_id, conversation_name, pin }, index) => (
            <span key={conversation_id}>
              <li
                key={conversation_id}
                className={`flex items-center  justify-between p-2   mt-3 overflow-visible whitespace-nowrap gap-2 ${
                  conversation_id === activeLink ? "bg-gray-700 rounded-lg" : ""
                }  ${
                  requestBased === "socket" &&
                  conversation_id === activeLink &&
                  "socketBtn"
                }`}
              >
                <>
                  {editingConversation === conversation_id ? (
                    <>
                      <span className="inline-flex items-center justify-center flex-shrink-0 h-7 w-7 rounded-md bg-primary-white">
                        <ChatQuoteIcon
                          color={
                            requestBased === "socket" ? "#5b54bd" : "#e40032"
                          }
                          width="12"
                        />
                      </span>
                      <input
                        type="text"
                        name="q"
                        className="py-2 text-sm text-white bg-transparent rounded-md focus:bg-transparent w-full focus:outline-none "
                        placeholder=""
                        autoComplete="off"
                        value={text}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleBlur(e, conversation_id)}
                        ref={(el) => (inputRef.current[index] = el)}
                      />
                      <button
                        type="submit"
                        className="p-1 focus:outline-none focus:shadow-outline"
                        onClick={() => setEditingConversation(null)}
                      >
                        <FaCheckCircle color="white" />
                      </button>
                    </>
                  ) : (
                    <>
                      <Link
                        to="#"
                        onClick={(e) => handleLinkClick(e, conversation_id)}
                        // onMouseEnter={() => handleLinkHover(conversation_id)}
                        className="flex gap-2 align-middle self-center items-center 	"
                      >
                        <span className="inline-flex items-center justify-center flex-shrink-0 h-7 w-7 rounded-md bg-primary-white">
                          <ChatQuoteIcon
                            color={
                              requestBased === "socket" ? "#5b54bd" : "#e40032"
                            }
                            width="12"
                          />
                        </span>
                        <span className="text-sm text-primary-white whitespace-nowrap ">
                          {conversation_name && conversation_name.length > 30
                            ? `${conversation_name.slice(0, 30)}...`
                            : conversation_name}
                        </span>
                      </Link>
                    </>
                  )}
                  {editingConversation !== conversation_id && (
                    <div className="z-100">
                      <span
                        id="dropdownHoverButton"
                        data-dropdown-toggle="dropdownHover"
                        data-dropdown-trigger="hover"
                      >
                        <BsThreeDotsVertical color="#fff" />
                        <div
                          id="dropdownHover"
                          className="z-10 fixed hidden  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:text-white"
                          aria-labelledby="dropdownHoverButton"
                        >
                          <ul className="py-2 text-sm text-gray-700">
                            <li>
                              <Link
                                to="#"
                                className="block px-4 py-2  dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white flex  gap-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleEdit(
                                    conversation_id,
                                    conversation_name,
                                    index
                                  );
                                }}
                              >
                                <span className="self-center">
                                  <AiFillEdit />{" "}
                                </span>{" "}
                                Edit
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="block px-4 py-2 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white flex  gap-2"
                                onClick={() =>
                                  pinConversationEvent(conversation_id)
                                }
                              >
                                {!pinned ? (
                                  <>
                                    <span className="self-center">
                                      {" "}
                                      <BsFillPinFill />{" "}
                                    </span>
                                    Pin
                                  </>
                                ) : (
                                  <>
                                    <span className="self-center">
                                      {" "}
                                      <PiPushPinSimpleSlashFill />{" "}
                                    </span>
                                    Remove
                                  </>
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="block px-4 py-2 dark:text-white hover:bg-gray-200  dark:hover:bg-gray-600 dark:hover:text-white flex  gap-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deleteConversationEvent(conversation_id);
                                }}
                              >
                                <span className="self-center">
                                  <MdDelete />{" "}
                                </span>
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                        {/* )} */}
                      </span>
                    </div>
                  )}
                </>
              </li>
            </span>
          )
        )}
    </ul>
  );
};

export default AgentSidebar;
