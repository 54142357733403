// src/redux/slices/chatSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topics: {}, // Each topicId will have an array of messages
};

export const agentSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { topicId, message } = action.payload;

      if (!state.topics[topicId]) {
        // Initialize a new topic if it doesn't exist
        state.topics[topicId] = [];
      }

      // Add the message to the respective topic
      state.topics[topicId].push(message);
    },
    clearTopic: (state, action) => {
      const { topicId } = action.payload;
      if (state.topics[topicId]) {
        delete state.topics[topicId]; // Remove a specific topic
      }
    },
    clearAllTopics: (state) => {
      state.topics = {}; // Clear all topics
    },
  },
});

export const { addMessage, clearTopic, clearAllTopics } = agentSlice.actions;
export default agentSlice.reducer;
