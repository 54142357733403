import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { credentials, dfpBaseV3Url } from "../constants/constants";
import Cookies from "js-cookie";
import { userEmail } from "../utils/utils";

// Fetching cookies and user details
let activeConfig = Cookies.get("activeConfig");

const email = userEmail();

export const agentConversationsApi = createApi({
  reducerPath: "agentConversationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseV3Url + "gpt/",
    prepareHeaders: (headers) => {
      const accessToken = Cookies.get("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getAgentConversations: builder.query({
      query: (id) => `agent/${id}/conversations?user_name=${email}`,
      providesTags: (result) =>
        result?.conversations
          ? [
              ...result.conversations.map(({ conversation_id }) => ({
                type: "agentConversation",
                id: conversation_id,
              })),
              { type: "agentConversation", id: "agentConversationList" },
            ]
          : [{ type: "agentConversation", id: "agentConversationList" }],
    }),
  }),
});

export const { useGetAgentConversationsQuery } = agentConversationsApi;
