import React, { useEffect, useRef, useState } from "react";

import Messager from "../Messager/Messager";
import { useSelector } from "react-redux";
import {
  useGetSocketHistoryQuery,
  useLazyGetSocketHistoryQuery,
  usePostConversationMutation,
} from "../../services/conversationService";
import { RedLoader } from "../Loaders/Loaders";
import Error from "../Error/Error";

import SingleMsg from "../SingleMsg/SingleMsg";
import WelcomeScreen from "../WelcomeComponenet/WelcomeScreen";

import { useDispatch } from "react-redux";
import { addQuestion, addResponse } from "../../services/socketSlice";
import SocketMessages from "../SocketMessages/SocketMessages";

import useWebSocket from "react-use-websocket";
import { dfpBaseUrl, loginPath, socketUrl } from "../../constants/constants";
import Cookies from "js-cookie";
import {
  generateCustomId,
  generateMongoLikeId,
  userEmail,
} from "../../utils/utils";
import { useAppDispatch } from "../../app/store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import useMessageHandler from "../../hooks/useMessageHandler";
import {
  setPrevSelectedConversation,
  setSelectedConversation,
} from "../../services/selectedConversation";
import { setNewConversation } from "../../services/newConversationSlice";
// import { newConversation } from "../../services/newConversationSlice";

const MySwal = withReactContent(Swal);

function getRandomId() {
  const randomString = Math.random().toString(36).substring(2, 15);
  const prefix = "random-";
  return prefix + randomString;
}

const SocketWrapper = ({
  menuOpen,
  showWelcome,
  setShowWelcome,
  myParam,
  setNewChat,
  botType,
}) => {
  const activeConfig = Cookies.get("activeConfig");
  const token = Cookies.get("crave_jwt_access_token");
  const email = userEmail();

  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const newCoversation = useSelector((state) => state.newCoversation);

  const [connectionStatus, setConnectionStatus] = useState("Connecting");

  const [text, setText] = useState("");
  const [prompt, setPromt] = useState();
  const [msgData, setMsgData] = useState();
  const [formBody, setFormBody] = useState();

  const [socketConversationStarted, setSocketConversationStarted] =
    useState(false);

  const [msgCounter, setMsgCounter] = useState(0);
  const msgCounterRef = useRef(msgCounter);

  const [questionId, setQuestionId] = useState();
  const questionIdRef = useRef(questionId);

  const selectedFilters = useSelector((state) => state.filters.selectedFilters);

  const getFilters = () => {
    let encodedFilters = "";
    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      const queryParams = new URLSearchParams(selectedFilters).toString();
      encodedFilters += `&${queryParams}`;
    }
    return encodedFilters;
  };

  const [webSocketUrl, setWebSocketUrl] = useState();

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    webSocketUrl,
    {
      onOpen: () => {
        setConnectionStatus("");
      },
      onError: async (error) => {
        console.log("🚀 ~ onError: ~ error:", error);

        setConnectionStatus("Error");
        try {
          const response = await fetch(dfpBaseUrl + "workforces/", {
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (response.status === 401) {
            let timerInterval;
            MySwal.fire({
              title: "",
              html: "Your token has expired. You will be redirected to login",
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: true,
              // showCancelButton: true,
              icon: "error",
              confirmButtonText: `Login`,

              didOpen: () => {
                MySwal.showLoading();
                // const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  // b.textContent = MySwal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.dismiss === MySwal.DismissReason.timer) {
                window.location.href = loginPath;
              }
            });
          }
        } catch (httpError) {}
      },
      onClose: (event) => {
        console.log(event);
        // setConnectionStatus("Error");
      },

      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      share: true,
    }
  );

  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const {
    data: socketData,
    error: socketDataError,
    isFetching: socketDataFetching,
  } = useGetSocketHistoryQuery(selectedConversation);

  // const [getSocketHistory] = useLazyGetSocketHistoryQuery();

  const [createMsg, createMsgInfo] = usePostConversationMutation();

  useEffect(() => {
    if (socketDataFetching) {
      setShowWelcome(false);
    } else {
      setShowWelcome(true);
    }
  }, [socketDataFetching]);

  const sendSocketMessage = (message) => {
    sendMessage(message.prompt);
  };

  useEffect(() => {
    setWebSocketUrl(
      `${socketUrl}?wid=${
        myParam ? myParam : activeConfig
      }${getFilters()}&user_id=${userEmail()}&conversation_id=${selectedConversation}&token=${token}`
    );

    // if (
    //   prevSelectedConversation &&
    //   prevSelectedConversation !== "nofetch" &&
    //   selectedConversation &&
    //   selectedConversation !== "nofetch"
    // ) {
    //   setSocketConversationStarted(false);
    //   setMsgCounter(0);
    //   setPromt();
    // }
    // if (
    //   prevSelectedConversation &&
    //   prevSelectedConversation !== "nofetch" &&
    //   selectedConversation &&
    //   selectedConversation === "nofetch"
    // ) {
    setSocketConversationStarted(false);
    setMsgCounter(0);
    setPromt();
    // }
  }, [selectedConversation, selectedFilters]);

  // useEffect(() => {
  //   if (
  //     selectedConversation &&
  //     selectedConversation !== "nofetch"
  //   ) {
  //     getSocketHistory(selectedConversation);
  //   }
  //   if (selectedConversation === "nofetch") setMsgData([]);
  //   if (selectedConversation) setShowWelcome(false);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getSocketHistory, selectedConversation, setShowWelcome]);

  useMessageHandler(
    lastMessage,
    selectedConversation,
    myParam,
    appDispatch,
    setMsgCounter,
    setSocketConversationStarted,
    setPromt,
    msgCounterRef,
    questionIdRef,
    newCoversation
  );

  useEffect(() => {
    if (socketData) {
      setMsgData(socketData);
      if (socketData.length > 0) {
        setShowWelcome(false);
      } else {
        setShowWelcome(true);
      }
    }
    if (
      socketData &&
      socketData.length > 0 &&
      selectedConversation &&
      selectedConversation !== "nofetch"
    ) {
      socketData.forEach((item) => {
        const newQuesId = getRandomId();
        dispatch(
          addQuestion({
            responseId: newQuesId,
            prompt: item.prompt,
            conversation_id: item.conversation_id,
          })
        );
        item?.messages?.forEach((response) => {
          if (response.sender !== "userproxy")
            dispatch(
              addResponse({
                responseId: newQuesId,
                answer: response.message,
                sender: response.sender,
                response_id: response.response_id,
                terminate: response.terminate,
              })
            );
        });
      });
    }
  }, [socketData]);

  useEffect(() => {
    questionIdRef.current = questionId;
  }, [questionId]);

  useEffect(() => {
    msgCounterRef.current = msgCounter;
  }, [msgCounter]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text) {
      setPromt(text);
      setShowWelcome(false);
      setSocketConversationStarted(true);
      const body = {
        text: text,
      };

      if (selectedConversation && selectedConversation !== "nofetch")
        body["conversation_id"] = selectedConversation;
      setFormBody(body);

      const newQuesId = getRandomId();
      setQuestionId(newQuesId);
      sendSocketMessage({
        prompt: text,
        sender: "user",
        response_id: newQuesId,
      });
      dispatch(
        addQuestion({
          responseId: newQuesId,
          prompt: text,
          conversation_id: selectedConversation,
        })
      );

      setText("");
    }
  };

  const terminateConversation = () => {
    setSocketConversationStarted(false);
    setMsgCounter(0);
    setPromt();
    getWebSocket().close();

    const id = generateCustomId();
    dispatch(setNewConversation(true));

    setNewChat([{ conversation_id: "nofetch", conversation_name: "New Chat" }]);

    dispatch(setPrevSelectedConversation(selectedConversation));
    dispatch(setSelectedConversation(id));
  };

  return (
    <main
      className={`w-full lg:w-3/4 fixed left-0 bottom-0  z-1 flex flex-col overflow-auto  transform ease-in-out duration-300 custom-height lg:ml-[24.5%]
      ${window.mapp ? "top-0" : "top-[91px]"} `}
    >
      <nav className="side-menu-nav flex-grow flex flex-col overflow-auto scroll-smooth ">
        {myParam && connectionStatus === "Error" && (
          <div className="mt-5 flex justify-center">
            <div className="bg-red-500 text-white font-medium px-2 py-1 rounded">
              <small>Not able to set up connection</small>
            </div>
          </div>
        )}
        <>
          {socketDataFetching && !socketConversationStarted ? (
            <div className="mx-auto mt-4">
              <RedLoader />
            </div>
          ) : socketDataError ? (
            <Error
              msg={
                socketDataError.error
                  ? socketDataError.error
                  : socketDataError.data.detail
              }
            />
          ) : (
            socketData &&
            socketData.length > 0 &&
            !showWelcome && (
              <SocketMessages
                socketData={socketData}
                socketConversationStarted={socketConversationStarted}
                selectedConversation={selectedConversation}
              />
            )
          )}

          {prompt && (
            <SingleMsg
              prompt={prompt}
              error={createMsgInfo.error}
              isLoading={socketConversationStarted}
              // handleRegenerate={handleRegenerate}
              requestBased={"socket"}
              socketData={msgData}
              newConversation={newCoversation}
            />
          )}
          <div ref={messagesEndRef} />
          {showWelcome && <WelcomeScreen myParam={myParam} />}
        </>
      </nav>
      <footer
        className="side-menu-footer flex-shrink-0 bg-skyblue  text-white py-5  border-primary-grey mx-auto"
        style={{ width: menuOpen ? "calc(100% - 25vw)" : "85%" }}
      >
        <form action="" onSubmit={(e) => handleSubmit(e)}>
          <Messager
            text={text}
            setText={setText}
            handleSubmit={handleSubmit}
            myParam={myParam}
            socketConversationStarted={socketConversationStarted}
            terminateConversation={terminateConversation}
          />
        </form>
      </footer>
    </main>
  );
};

export default SocketWrapper;
