import { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

import { current } from "@reduxjs/toolkit";

import { agentConversationsApi } from "../services/agentConversations";
import { userEmail } from "../utils/utils";

const useAgentMessageHandler = (
  lastMessage,
  selectedConversation,
  myParam,
  appDispatch,
  setMsgCounter,
  setSocketConversationStarted,
  setPromt,
  msgCounterRef,
  questionIdRef,

  newCoversation
) => {
  const dispatch = useDispatch();

  const handleAgentNewConversation = useCallback(
    (parsedData) => {
      const { conversation_id, content, name, role, initial_prompt } =
        parsedData;

      appDispatch(
        agentConversationsApi.util.updateQueryData(
          "getAgentConversations",
          myParam ? myParam : "",
          (draftPosts) => {
            const prompt = localStorage.getItem("currentPrompt");
            localStorage.removeItem("currentPrompt");
            const email = userEmail();

            const userPrompt = {
              content: prompt,
              feedback: { vote: 0, comment: "" },
              name: "user_proxy",
              role: "assistant",
            };

            const newConversations = {
              _id: conversation_id,
              conversation_name: prompt,
              app_uuid: myParam,
              object: "agent",
              object_uuid: 123,
              yum_id: email,
              chat_history: [
                userPrompt,
                {
                  content,
                  name,
                  role,
                  feedback: { vote: 0, comment: "" },
                  conversation_id,
                  initial_prompt,
                },
              ],
              deleted: false,
            };
            const conversationExists = draftPosts.conversations.some(
              (conversation) => {
                console.log("🚀 ~ conversation:", conversation);
              }
            );

            if (!conversationExists) {
              draftPosts.conversations.unshift(newConversations);
              setSocketConversationStarted(false);
              setMsgCounter(0);
              setPromt();
            } else {
              console.log(
                "Conversation with conversation_id already exists:",
                conversation_id
              );
            }
          }
        )
      );
    },
    [
      appDispatch,
      myParam,
      setMsgCounter,
      setPromt,
      setSocketConversationStarted,
    ]
  );

  const handleAgentSocketHistory = useCallback(
    (parsedData, selectedConversation) => {
      console.log("handleAgentSocketHistory", handleAgentSocketHistory);

      let { conversation_id, content, name, role, initial_prompt } = parsedData;

      conversation_id = conversation_id
        ? conversation_id
        : selectedConversation;
      const checkAndUpdateCache = () => {
        const state = appDispatch((_, getState) => getState());
        const existingCache =
          agentConversationsApi.endpoints.getAgentConversations.select(myParam)(
            state
          );

        console.log("🚀 ~ checkAndUpdateCache ~ existingCache:", existingCache);

        if (existingCache && existingCache.data) {
          const data = existingCache.data;
          //   if (data.length === 1 && data[0].terminate) {
          //     dispatch(setNewConversation(true));
          //   }
          console.log(myParam, "myParam");

          appDispatch(
            agentConversationsApi.util.updateQueryData(
              "getAgentConversations",
              myParam,
              (draftPosts) => {
                if (draftPosts) {
                  const copy = current(draftPosts);
                  console.log(copy, "copy");

                  const prompt = localStorage.getItem("currentPrompt");
                  localStorage.removeItem("currentPrompt");

                  const updated = copy.conversations.map((item) => {
                    const newObj = { ...item };

                    if (newObj._id === conversation_id) {
                      let existingHistory = [...newObj.chat_history];
                      const userPrompt = {
                        content: prompt,
                        feedback: { vote: 0, comment: "" },
                        name: "user_proxy",
                        role: "assistant",
                      };

                      existingHistory.push(userPrompt);

                      existingHistory.push({
                        content,
                        name,
                        role,
                        feedback: { vote: 0, comment: "" },
                        conversation_id,
                        initial_prompt,
                      });
                      newObj.chat_history = existingHistory;
                    }
                    return newObj;
                  });
                  draftPosts.conversations = updated;
                  setSocketConversationStarted(false);
                  setMsgCounter(0);
                  setPromt();
                } else {
                  console.warn("No draftPosts available to update.");
                }
              }
            )
          );
        }
      };

      checkAndUpdateCache();
    },
    [
      appDispatch,
      myParam,
      setMsgCounter,
      setPromt,
      setSocketConversationStarted,
    ]
  );

  //   const handleAgentResponse = useCallback(
  //     (parsedData) => {
  //       const { message, sender, terminate } = parsedData.data;
  //       const latestQuestionId = questionIdRef.current;

  //       if (sender && sender !== "userproxy") {
  //         dispatch(
  //           addResponse({
  //             responseId: latestQuestionId,
  //             answer: message.content,
  //             sender,
  //             terminate,
  //           })
  //         );
  //       }

  //       if (terminate === true) {
  //         setSocketConversationStarted(false);
  //         setMsgCounter(0);
  //         setPromt();
  //       }
  //     },
  //     [
  //       questionIdRef,
  //       dispatch,
  //       setSocketConversationStarted,
  //       setMsgCounter,
  //       setPromt,
  //     ]
  //   );

  useEffect(() => {
    if (lastMessage) {
      console.log("🚀 ~ useEffect ~ lastMessage:", lastMessage);

      const { data } = lastMessage;
      const parsedData = JSON.parse(data);
      console.log("🚀 ~ useEffect ~ parsedData:", parsedData);

      if (parsedData.content) {
        if (newCoversation) {
          console.log("New Conversation");
          handleAgentNewConversation(parsedData);
        } else if (selectedConversation) {
          console.log("Adding to conversation history");

          handleAgentSocketHistory(parsedData, selectedConversation);
        }
        // handleAgentResponse(parsedData);
      } else if (parsedData.error) {
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastMessage,
    handleAgentNewConversation,
    handleAgentSocketHistory,
    // handleAgentResponse,
  ]);
};

export default useAgentMessageHandler;
